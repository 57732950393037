import { useState } from "react";

import abi from "@shoyunft/contracts/abis/INFT721.json";
import { INFT721 } from "@shoyunft/contracts/typechain";
import { useEthers } from "@usedapp/core";
import { Contract } from "ethers";
import { useAsyncEffect } from "use-async-effect";

const ADDRESS = "0xb8b07d0f2990ddd5b99b6db59dd8356ca2b1302d";

const useStupidMortys = () => {
    const { library, account } = useEthers();
    const [error, setError] = useState("");
    const [loading, setLoading] = useState(false);
    const [myTokenId, setMyTokenId] = useState<number>();

    useAsyncEffect(async () => {
        if (library && account) {
            setLoading(true);
            setMyTokenId(undefined);
            try {
                const contract = new Contract(ADDRESS, abi, library) as INFT721;
                const tokenIdsIn = (await contract.queryFilter(contract.filters.Transfer(null, account))).map(
                    e => e.args.tokenId
                );
                const tokenIdsOut = (await contract.queryFilter(contract.filters.Transfer(account, null))).map(
                    e => e.args.tokenId
                );
                const tokenIds = tokenIdsIn.filter(id => !tokenIdsOut.includes(id));
                if (tokenIds.length > 0) {
                    setMyTokenId(tokenIds[tokenIds.length - 1].toNumber());
                }
            } catch (e) {
                setError((e as Error).message);
            } finally {
                setLoading(false);
            }
        }
    }, [library, account]);

    return {
        loading,
        error,
        myTokenId,
    };
};

export default useStupidMortys;
