import React from "react";

const Section1 = () => {
    return (
        <div>
            <div className="container min-safe-h-screen mx-auto pt-24 md:flex md:pt-72 justify-between">
                <div className="w-full md:w-2/3 mt-12">
                    <div className="text-5xl lg:text-6xl xl:text-7xl leading-snug lg:leading-snug xl:leading-snug">
                        S.O.S.!
                    </div>
                    <div className="text-3xl lg:text-4xl xl:text-5xl leading-snug lg:leading-snug xl:leading-snug">
                        6969 SHARKS
                    </div>
                    <div className="text-3xl lg:text-4xl xl:text-5xl leading-snug lg:leading-snug xl:leading-snug">
                        ARE COMING OUT
                    </div>
                    <div className="text-md md:text-xl sm:leading-normal mt-4">MINTING IS LIVE, NOM NOM</div>
                    <div className="mt-4 lg:mt-12">
                        <a
                            href="/#/mint"
                            className="inline-block w-full md:w-auto bg-text text-white text-sm text-center md:text-lg px-8 md:px-12 py-4 leading-none border rounded hover:backdrop-sepia mt-4 lg:mt-0"
                        >
                            MINT NOW
                        </a>
                        <a
                            href="https://opensea.io/collection/levx-dao-sharkpunks?search[sortAscending]=false&search[sortBy]=LAST_SALE_PRICE"
                            target="_blank"
                            rel="noreferrer"
                            className="inline-block w-full md:w-auto text-sm text-center md:text-lg px-8 md:px-12 py-4 leading-none border rounded hover:backdrop-sepia mt-4 lg:mt-0 ml-0 lg:ml-4"
                        >
                            OPENSEA
                        </a>
                    </div>
                </div>
                <div className="w-full p-16 md:w-1/2 md:p-0 md:pt-4 lg:pl-0 xl:pt-8">
                    <img className="w-full object-fill" src="/img/shark.png" alt="Shark" />
                </div>
            </div>
        </div>
    );
};

export default Section1;
