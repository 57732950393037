import React, { useEffect } from "react";

import { useAuth0, User } from "@auth0/auth0-react";

const DiscordAccountButton = (props: { redirectUri: string; onLogin?: (user: User) => void }) => {
    const { isLoading, user, loginWithRedirect, logout } = useAuth0();

    const onLogin = async () => {
        await loginWithRedirect({
            connection: "discord",
            redirectUri: props.redirectUri,
        });
    };
    const onLogout = async () => {
        await logout({ returnTo: window.location.href });
    };

    useEffect(() => {
        if (user) {
            props.onLogin?.(user);
        }
    }, [user]);
    return (
        <div>
            {user && (
                <div>
                    <button
                        onClick={onLogout}
                        className="inline-block w-full text-sm px-4 py-4 leading-none border rounded hover:backdrop-sepia"
                    >
                        LOG OUT ({user.name})
                    </button>
                </div>
            )}
            {!user && (
                <button
                    onClick={onLogin}
                    className="inline-block w-full text-sm px-4 py-4 leading-none border rounded hover:backdrop-sepia text-white border-discord bg-discord"
                    disabled={isLoading}
                >
                    {isLoading ? "SIGNING IN..." : "SIGN IN WITH DISCORD"}
                </button>
            )}
        </div>
    );
};

export default DiscordAccountButton;
