import React from "react";

const Header = () => {
    return (
        <div className="mt-64">
            <div className="border border-b-text bg-primary">
                <div className="container mx-auto h-16 md:h-24 flex items-center justify-between">
                    <div className="w-full md:w-1/3 text-sm md:text-lg">
                        <a href="/#/">Sharkpunks</a>
                    </div>
                    <div className="w-full md:w-2/3 text-left md:text-right text-xs md:text-md">
                        2022 SHARKPUNKS UNDER WTFPL LICENSE
                    </div>
                </div>
            </div>
        </div>
    );
};

export default Header;
