import React from "react";

import { useParams } from "react-router-dom";

import OhGeez from "../components/OhGeez";
import Top69 from "../components/Top69";
import Twitter750 from "../components/Twitter750";
import useClaimState from "../hooks/useClaimState";

const Claim = () => {
    const { slug } = useParams();
    const state = useClaimState(slug || "");

    return (
        <div className="container mx-auto">
            <div>
                <div className="text-3xl lg:text-4xl xl:text-5xl sm:leading-snug lg:leading-snug xl:leading-snug mt-40 text-right">
                    EARLY CONTRIBUTORS
                </div>
                <div className="text-xl lg:text-2xl xl:text-3xl sm:leading-snug lg:leading-snug xl:leading-snug text-right">
                    {slug == "750" ? "750 AIRDROP EVENT PARTICIPANTS" : slug == "oh-geez" ? "OH-GEEZ" : "top69"}
                </div>
            </div>
            {slug === "750" ? (
                <Twitter750 state={state} />
            ) : slug === "oh-geez" ? (
                <OhGeez state={state} />
            ) : (
                <Top69 state={state} />
            )}
        </div>
    );
};

export default Claim;
