import React from "react";

const TwitterButton = () => {
    return (
        <a
            href="https://twitter.com/SharkpunksNFT"
            target="_blank"
            rel="noreferrer"
            className="inline-block w-full md:w-auto text-sm text-center md:text-md mr-4 px-8 py-4 leading-none border border-twitter rounded hover:backdrop-sepia bg-twitter text-white mt-4 lg:mt-0"
        >
            TWITTER
        </a>
    );
};

export default TwitterButton;
