import React from "react";

const Section3 = () => {
    return (
        <div>
            <div className="container mx-auto">
                <div className="text-3xl lg:text-4xl xl:text-5xl sm:leading-snug lg:leading-snug xl:leading-snug text-center">
                    SIGNIFICANT OFFERING OF SHARKS
                </div>
                <a
                    href="https://sharkpunks.medium.com/s-o-s-significant-offering-of-sharks-sharkpunks-2-0-c65297f1b36f"
                    target="_blank"
                    rel="noreferrer"
                >
                    <img
                        className="mt-8 w-full md:w-2/3 mx-auto"
                        src="https://miro.medium.com/max/1400/0*SjTW3b7uBYD4wg1l.png"
                    />
                </a>
                <div className="text-sm leading-loose mt-8 mb-4">
                    SHARKS WILL BE MINTED VIA A STAGGERED REVEAL, UNTIL THE FINAL NFT IS MINTED. PRICE WILL BE
                    DETERMINED BY A SIGMOID CURVE, STARTING AT 0.0333 ETH AND INCREASING BY 0.2496% UNTIL 5,817 SHARKS
                    ARE MINTED WITH THE FINAL PRICE 93.7 ETH
                    <br />
                    (OH..GEEZ a.k.a. STUPID MORTY NFT HOLDERS RECEIVE 10% DISCOUNT OF MINTING PRICE)
                </div>
                <a
                    href="https://sharkpunks.medium.com/s-o-s-significant-offering-of-sharks-sharkpunks-2-0-c65297f1b36f"
                    target="_blank"
                    rel="noreferrer"
                    className="inline-block w-full bg-text text-white text-sm text-center md:text-md px-8 py-4 leading-none border rounded hover:backdrop-sepia mt-4 lg:mt-0"
                >
                    ANNOUNCEMENT
                </a>
                <div className="text-2xl leading-snug text-center mt-16 mb-8">MINTING PRICE CURVE</div>
                <a
                    href="https://docs.google.com/spreadsheets/d/1_uOHgorWjOqUg2NLRM33IT4P4u3djxVExOtIVQsIGis/edit?usp=sharing"
                    target="_blank"
                    rel="noreferrer"
                >
                    <img
                        className="mt-8 w-full md:w-2/3 mx-auto"
                        src="https://miro.medium.com/max/1400/1*lf37sisloP9nrd1EIpNkQw.png"
                        alt="curve"
                    />
                </a>
                <div className="text-2xl leading-snug text-center mt-16 mb-8">333 RETRO SHARKS</div>
                {new Array(333).fill(0).map((_, index) => {
                    const file = "E" + (index + 1) + ".png";
                    return (
                        <img
                            key={index}
                            className="inline-block w-1/6 md:w-1/12"
                            src={"img/section3/" + file}
                            alt={file}
                        />
                    );
                })}
                <div className="text-center text-lg leading-loose mt-8">AND 5,484 MORE...</div>
            </div>
        </div>
    );
};

export default Section3;
