import React from "react";

import useSharkpunks from "../hooks/useSharkpunks";
import SharkImage from "./SharkImage";

const Section4 = () => {
    const { loadingNextTokenId, nextTokenId } = useSharkpunks({ upperLimit: 1083 });
    return (
        <div>
            <div className="container mx-auto mt-72">
                <div className="md:flex">
                    <div className="hidden md:block md:w-1/4">
                        <SharkImage tokenId={nextTokenId} loading={loadingNextTokenId} />
                    </div>
                    <div className="md:w-3/4 md:pl-8">
                        <div className="text-3xl text-right lg:text-4xl xl:text-5xl sm:leading-snug lg:leading-snug xl:leading-snug text-center">
                            EARLY CONTRIBUTORS
                        </div>
                        <div className="text-sm leading-loose mt-4 mb-8">
                            IF YOU&apos;RE EITHER ONE OF 750 AIRDROP EVENT PARTICIPANTS, OH-GEEZ OF LEVXDAO DISCORD OR
                            69 TOP-LOUDEST SHARKPUNKS DISCORD MEMBERS, YOU CAN MINT ONE SHARK FOR FREE.
                        </div>
                        <div className="block md:flex justify-end">
                            <a
                                href="/#/claim/750"
                                className="inline-block w-full md:w-auto text-sm px-4 py-4 leading-none border rounded hover:backdrop-sepia lg:mt-0"
                            >
                                750 AIRDROP EVENT
                            </a>
                            <a
                                href="/#/claim/oh-geez"
                                className="inline-block w-full md:w-auto text-sm px-4 py-4 leading-none border rounded hover:backdrop-sepia mt-2 lg:mt-0 md:mt-0 md:ml-2"
                            >
                                OH-GEEZ
                            </a>
                            <a
                                href="/#/claim/top69"
                                className="inline-block w-full md:w-auto text-sm px-4 py-4 leading-none border rounded hover:backdrop-sepia mt-2 lg:mt-0 md:mt-0 md:ml-2"
                            >
                                TOP 69 IN DISCORD
                            </a>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default Section4;
