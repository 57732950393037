import React from "react";

import { useEthers } from "@usedapp/core";
import { BigNumber, utils } from "ethers";

import AccountButton from "../components/AccountButton";
import Loading from "../components/Loading";
import useSigmoidCurveOffering from "../hooks/useSigmoidCurveOffering";
import useStupidMortys from "../hooks/useStupidMortys";

const BASE = BigNumber.from(10).pow(12);

const Mint = () => {
    const { account } = useEthers();
    const {
        loading: loadingSCO,
        error,
        tokenId,
        price,
        minting,
        onMint,
        onMintDiscounted,
        onMintBatch,
        onMintBatchDiscounted,
        myTokenId,
    } = useSigmoidCurveOffering();
    const { loading: loadingStupidMortys, myTokenId: myStupidMortysTokenId } = useStupidMortys();
    const loading = loadingSCO || loadingStupidMortys;
    return (
        <div className="container mx-auto">
            <div>
                <div className="text-3xl lg:text-4xl xl:text-5xl leading-snug lg:leading-snug xl:leading-snug mt-40">
                    MINT SHARKS
                </div>
                <div className="text-xl lg:text-2xl xl:text-3xl leading-snug lg:leading-snug xl:leading-snug mt-4">
                    CURRENT PRICE - {loading ? "LOADING..." : utils.formatEther(price.div(BASE).mul(BASE)) + " ETH"}
                </div>
                <div className="mt-8 text-xs md:text-sm">
                    THE ACTUAL MINTING PRICE COULD BE A LITTLE BIT HIGHER THAN WHAT IT SHOWS (IF SOMEONE MINTS IN THE
                    SAME BLOCK BEFORE YOU).
                </div>
                <img
                    className="w-full md:w-2/3 mx-auto mt-16"
                    src="https://miro.medium.com/max/1400/1*lf37sisloP9nrd1EIpNkQw.png"
                    alt="curve"
                />
                {loading && <Loading />}
                <div className="w-full md:w-2/3 mx-auto mt-8">
                    {!loading && !account && <AccountButton fullWidth={true} />}
                    {!loading && account && !myTokenId && (
                        <>
                            <button
                                className="w-full bg-text text-white text-sm text-center md:text-lg px-8 md:px-12 py-4 leading-none border rounded hover:backdrop-sepia mt-4 lg:mt-0"
                                onClick={myStupidMortysTokenId ? onMintDiscounted : onMint}
                                disabled={minting}
                            >
                                {minting ? "MINTING..." : `MINT #${tokenId}`}
                            </button>
                            <div className="text-center my-4">or</div>
                            <div className="md:flex">
                                <button
                                    className="w-full md:w-auto md:flex-1 bg-transparent text-black text-sm text-center md:text-lg px-8 md:px-12 py-4 leading-none border rounded hover:backdrop-sepia mr-0 md:mr-2"
                                    onClick={
                                        myStupidMortysTokenId ? () => onMintBatchDiscounted(3) : () => onMintBatch(3)
                                    }
                                    disabled={minting}
                                >
                                    {minting ? "MINTING..." : "MINT 3 SHARKS"}
                                </button>
                                <button
                                    className="w-full md:w-auto md:flex-1 bg-transparent text-black text-sm text-center md:text-lg px-8 md:px-12 py-4 leading-none border rounded hover:backdrop-sepia mt-2 lg:mt-0 ml-0 md:ml-2"
                                    onClick={
                                        myStupidMortysTokenId ? () => onMintBatchDiscounted(10) : () => onMintBatch(10)
                                    }
                                    disabled={minting}
                                >
                                    {minting ? "MINTING..." : "MINT 10 SHARKS"}
                                </button>
                            </div>
                            <div className="md:flex mt-2">
                                <button
                                    className="w-full md:w-auto md:flex-1 bg-transparent text-black text-sm text-center md:text-lg px-8 md:px-12 py-4 leading-none border rounded hover:backdrop-sepia mr-0 md:mr-2"
                                    onClick={
                                        myStupidMortysTokenId ? () => onMintBatchDiscounted(33) : () => onMintBatch(33)
                                    }
                                    disabled={minting}
                                >
                                    {minting ? "MINTING..." : "MINT 33 SHARKS"}
                                </button>
                                <button
                                    className="w-full md:w-auto md:flex-1 bg-transparent text-black text-sm text-center md:text-lg px-8 md:px-12 py-4 leading-none border rounded hover:backdrop-sepia mt-2 lg:mt-0 ml-0 md:ml-2"
                                    onClick={
                                        myStupidMortysTokenId
                                            ? () => onMintBatchDiscounted(100)
                                            : () => onMintBatch(100)
                                    }
                                    disabled={minting}
                                >
                                    {minting ? "MINTING..." : "MINT 100 SHARKS"}
                                </button>
                            </div>
                            <div className="md:flex mt-2">
                                <button
                                    className="w-full md:w-auto md:flex-1 bg-transparent text-black text-sm text-center md:text-lg px-8 md:px-12 py-4 leading-none border rounded hover:backdrop-sepia mr-0 md:mr-2"
                                    onClick={
                                        myStupidMortysTokenId
                                            ? () => onMintBatchDiscounted(333)
                                            : () => onMintBatch(333)
                                    }
                                    disabled={minting}
                                >
                                    {minting ? "MINTING..." : "MINT 333 SHARKS"}
                                </button>
                                <button
                                    className="w-full md:w-auto md:flex-1 bg-transparent text-black text-sm text-center md:text-lg px-8 md:px-12 py-4 leading-none border rounded hover:backdrop-sepia mt-2 lg:mt-0 ml-0 md:ml-2"
                                    onClick={
                                        myStupidMortysTokenId
                                            ? () => onMintBatchDiscounted(1000)
                                            : () => onMintBatch(1000)
                                    }
                                    disabled={minting}
                                >
                                    {minting ? "MINTING..." : "MINT 1000 SHARKS"}
                                </button>
                            </div>
                            {myStupidMortysTokenId && (
                                <div className="text-sm text-metamask mt-4">
                                    you&apos;LL GET 10% DISCOUNT BECAUSE YOU&apos;RE THE OWNER OF STUPID MORTY #
                                    {myStupidMortysTokenId}
                                </div>
                            )}
                        </>
                    )}
                    {!loading && account && (
                        <a
                            href={"/#/collection/" + account}
                            className="block w-full bg-transparent text-black text-sm text-center md:text-lg px-8 md:px-12 py-4 leading-none border rounded hover:backdrop-sepia mt-4 lg:mt-0"
                        >
                            CHECK YOUR COLLECTION
                        </a>
                    )}
                    {error && <div className="text-sm text-red mt-8">{error}</div>}
                </div>
            </div>
        </div>
    );
};

export default Mint;
