import React from "react";

import Section1 from "../components/Section1";
import Section2 from "../components/Section2";
import Section3 from "../components/Section3";
import Section4 from "../components/Section4";
import Section5 from "../components/Section5";

const Home = () => {
    return (
        <div>
            <Section1 />
            <Section3 />
            <Section2 />
            <Section4 />
            <Section5 />
        </div>
    );
};

export default Home;
