import React from "react";

import { Bars } from "react-loader-spinner";

const Loading = () => {
    return (
        <div className="w-full h-16 flex justify-center items-center mt-8">
            <Bars width="36" height="36" color="black" />
        </div>
    );
};

export default Loading;
