import React from "react";

import { useEthers } from "@usedapp/core";

import { MAX_SHARKS } from "../constants";
import { ClaimState } from "../hooks/useClaimState";
import AccountButton from "./AccountButton";
import DiscordAccountButton from "./DiscordAccountButton";
import DiscordButton from "./DiscordButton";
import SharkImage from "./SharkImage";
import TwitterButton from "./TwitterButton";

const OhGeez = ({ state }: { state: ClaimState }) => {
    return (
        <div>
            {state.nextTokenId && state.nextTokenId >= MAX_SHARKS ? (
                <div className={"w-full"}>
                    <div className={"text-5xl lg:text-6xl xl:text-7xl text-center mt-32"}>MINTING ENDED</div>
                    <div className={"flex justify-center pt-16"}>
                        <TwitterButton />
                        <DiscordButton />
                    </div>
                </div>
            ) : (
                <div className="lg:flex mt-8">
                    <div className="w-full lg:w-1/2 mt-8">
                        <Step1 />
                    </div>
                    <div className="w-full lg:w-1/2 mt-8 lg:pl-16 flex flex-col justify-between">
                        <div>
                            <Step2 state={state} />
                            {state.user && state.auth && <Step3 state={state} />}
                            {state.error && <div className="text-red mt-4">{state.error}</div>}
                        </div>
                        <div className="my-4 px-16">
                            <SharkImage tokenId={state.nextTokenId} loading={state.loadingNextTokenId} />
                        </div>
                    </div>
                </div>
            )}
        </div>
    );
};

const Step1 = () => {
    return (
        <div>
            <div className="mb-8">1) IF YOU HAVE OH-GEEZ ROLE IN THE LEVX DAO DISCORD, YOU&apos;RE ELIGIBLE:</div>
            <img src="/img/oh-geez.png" alt="oh-geez" />
        </div>
    );
};

const Step2 = ({ state }: { state: ClaimState }) => {
    return (
        <div>
            <div className="mb-4">2) TO MINT IT, SIGN IN WITH YOUR DISCORD ACCOUNT.</div>
            <DiscordAccountButton
                redirectUri={window.location.origin + "/#/claim/" + state.slug}
                onLogin={state.setUser}
            />
        </div>
    );
};

const Step3 = ({ state }: { state: ClaimState }) => {
    const { account } = useEthers();
    return (
        <div>
            <div className="mt-8 mb-4">4) CONNECT YOUR WALLET & CLAIM</div>
            {!account && <AccountButton fullWidth={true} inverted={true} />}
            {account && <ClaimButton state={state} />}
        </div>
    );
};

const ClaimButton = ({ state }: { state: ClaimState }) => {
    return (
        <button
            onClick={state.onClaim}
            className={
                "inline-block text-sm px-4 py-4 leading-none border rounded hover:backdrop-sepia mt-4 lg:mt-0 w-full bg-text text-white"
            }
            disabled={state.loadingNextTokenId}
        >
            {state.loadingNextTokenId ? "LOADING..." : `CLAIM SHARKPUNK #${state.nextTokenId}`}
        </button>
    );
};

export default OhGeez;
