import { useState } from "react";

import abi from "@shoyunft/contracts/abis/INFT721.json";
import { INFT721 } from "@shoyunft/contracts/typechain";
import { useEthers } from "@usedapp/core";
import { constants, Contract } from "ethers";
import { useAsyncEffect } from "use-async-effect";

const ADDRESS = "0xa59a5b0c946086d6884455a6a556729d747d16d3";

const useSharkpunks = (options?: { upperLimit?: number; account?: string }) => {
    const { library } = useEthers();
    const [loadingNextTokenId, setLoadingNextTokenId] = useState(false);
    const [nextTokenId, setNextTokenId] = useState<number>();
    const [loadingMyTokenIds, setLoadingMyTokenIds] = useState(false);
    const [myTokenIds, setMyTokenIds] = useState<number[]>();

    useAsyncEffect(async () => {
        if (library) {
            setLoadingNextTokenId(true);
            try {
                const contract = new Contract(ADDRESS, abi, library) as INFT721;
                let events = await contract.queryFilter(contract.filters.Transfer(constants.AddressZero));
                if (options?.upperLimit) {
                    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                    // @ts-ignore
                    events = events.filter(event => event.args.tokenId.toNumber() < options.upperLimit);
                }
                const id = events[events.length - 1].args.tokenId.toNumber() + 1;
                setNextTokenId(id);
            } finally {
                setLoadingNextTokenId(false);
            }
        }
    }, [library]);

    useAsyncEffect(async () => {
        if (library && options?.account) {
            setLoadingMyTokenIds(true);
            try {
                const contract = new Contract(ADDRESS, abi, library) as INFT721;
                const tokenIdsIn = (await contract.queryFilter(contract.filters.Transfer(null, options?.account))).map(
                    event => event.args.tokenId.toNumber()
                );
                const tokenIdsOut = (await contract.queryFilter(contract.filters.Transfer(options?.account, null))).map(
                    event => event.args.tokenId.toNumber()
                );
                let tokenIds = tokenIdsIn.filter(tokenId => !tokenIdsOut.includes(tokenId));
                if (options?.upperLimit) {
                    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                    // @ts-ignore
                    tokenIds = tokenIds.filter(tokenId => tokenId < options.upperLimit);
                }
                setMyTokenIds(tokenIds.sort((a, b) => b - a));
            } finally {
                setLoadingMyTokenIds(false);
            }
        }
    }, [library && options?.account]);

    return {
        loadingNextTokenId,
        nextTokenId,
        loadingMyTokenIds,
        myTokenIds,
    };
};

export default useSharkpunks;
