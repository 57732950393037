import React, { useEffect } from "react";

import { shortenAddress, useEthers, useLookupAddress } from "@usedapp/core";
import WalletConnectProvider from "@walletconnect/web3-provider";
import Web3Modal from "web3modal";

import { ALCHEMY_API_KEY } from "../constants";

const AccountButton = (props: { fullWidth?: boolean; inverted?: boolean }) => {
    const { isLoading, chainId, account, deactivate, activate, switchNetwork } = useEthers();
    const ens = useLookupAddress();
    const { error } = useEthers();

    useEffect(() => {
        if (error) {
            alert(error.message);
        }
    }, [error]);

    useEffect(() => {
        if (chainId != 1) {
            deactivate();
        }
    }, [chainId]);

    const onClick = async () => {
        if (account) {
            if (confirm("Do you want to disconnect?")) {
                deactivate();
            }
        } else {
            if (chainId != 1) {
                await switchNetwork(1);
            }

            const providerOptions = {
                injected: {
                    display: {
                        name: "Metamask",
                        description: "CONNECT WITH THE PROVIDER IN YOUR BROWSER",
                    },
                    package: null,
                },
                walletconnect: {
                    package: WalletConnectProvider,
                    options: {
                        rpc: {
                            1: ALCHEMY_API_KEY,
                        },
                    },
                },
            };

            const web3Modal = new Web3Modal({
                providerOptions,
            });
            try {
                const provider = await web3Modal.connect();
                await activate(provider);
            } catch (error) {
                const message = (error as Error).message;
                if (message && !message.startsWith("User")) alert(message);
            }
        }
    };

    return (
        <button
            onClick={onClick}
            className={
                "inline-block text-sm md:text-base px-4 py-2 md:py-4 leading-none rounded" +
                (!account ? " border hover:backdrop-sepia" : "") +
                (props.fullWidth ? " w-full" : "") +
                (props.inverted ? " bg-text text-white" : "")
            }
            disabled={isLoading}
        >
            {account ? (
                <span>{ens ?? shortenAddress(account)}</span>
            ) : (
                <div>
                    <span className="hidden md:block">
                        {chainId == 1 ? (isLoading ? "CONNECTING..." : "CONNECT WALLET") : "SWITCH NETWORK"}
                    </span>
                    <span className="block md:hidden">
                        {chainId == 1 ? (isLoading ? "..." : "CONNECT") : "SWITCH NETWORK"}
                    </span>
                </div>
            )}
        </button>
    );
};

export default AccountButton;
