import React from "react";

import { ClaimState } from "../hooks/useClaimState";
import DiscordButton from "./DiscordButton";
import TwitterButton from "./TwitterButton";

const Twitter750 = ({ state }: { state: ClaimState }) => {
    return (
        <div>
            <div className={"w-full"}>
                <div className={"text-5xl lg:text-6xl xl:text-7xl text-center mt-32"}>MINTING ENDED</div>
                <div className={"flex justify-center pt-16"}>
                    <TwitterButton />
                    <DiscordButton />
                </div>
            </div>
        </div>
    );
};

export default Twitter750;
