import abi from "@levxdao/airdrop/abis/NFTAirdrops.json";
import deployment from "@levxdao/airdrop/deployments/mainnet/NFTAirdrops.json";
import deployment2 from "@levxdao/airdrop/deployments/mainnet/NFTAirdrops2.json";
import { NFTAirdrops } from "@levxdao/airdrop/typechain/NFTAirdrops";
import { useEthers } from "@usedapp/core";
import { Contract } from "ethers";

const useNFTAirdrops = (second?: boolean) => {
    const { library, account } = useEthers();

    const claimShark = async (slug: string, id: string, v: number, r: string, s: string) => {
        if (library && account) {
            const { address } = second ? deployment2 : deployment;
            const contract = new Contract(address, abi, library.getSigner()) as NFTAirdrops;
            const tx = await contract.claim(slug, id, v, r, s, account, "0x");
            await tx.wait();
        }
    };

    return {
        claimShark,
    };
};

export default useNFTAirdrops;
