import React from "react";

import { useEthers } from "@usedapp/core";
import { GiSharkFin } from "react-icons/gi";

import AccountButton from "./AccountButton";

const Header = () => {
    const { account } = useEthers();
    return (
        <div className="fixed w-full top-0 border border-b-text bg-primary">
            <div className="container mx-auto h-16 md:h-24 flex items-center justify-between">
                <div className="text-lg md:text-2xl font-arcade">
                    <a href="/#/">Sharkpunks</a>
                </div>
                <div className="flex justify-end items-center mt-2 md:mt-0">
                    {account && (
                        <a className="mr-12 hidden md:block" href={"/#/collection/" + account}>
                            My COLLECTION
                        </a>
                    )}
                    <AccountButton />
                    {account && (
                        <a className={"block md:hidden px-1 py-1 mt-1"} href={"/#/collection/" + account}>
                            <GiSharkFin fontSize={18} />
                        </a>
                    )}
                </div>
            </div>
        </div>
    );
};

export default Header;
