import React from "react";

import { utils } from "ethers";

import Loading from "./Loading";

const SharkImage = (props: { tokenId?: number; loading?: boolean }) => {
    const image =
        props.tokenId != undefined
            ? props.tokenId < 1083
                ? "https://sharkpunks.mypinata.cloud/ipfs/QmaBVrnWAJqgegXCsBAzFmE7bD1k3U6QrSMFS41cAyWYGA/" +
                  props.tokenId.toString().padStart(5, "0") +
                  ".png"
                : "https://sharkpunks.mypinata.cloud/ipfs/QmV74F65pv5SRxeCekLytsrwJcrp5Z1nPT8SqNnaLeUwpY/" +
                  utils.id(props.tokenId.toString().padStart(5, "0")) +
                  ".png"
            : "";
    return (
        <div className="w-full p-2">
            {props.loading ? (
                <Loading />
            ) : (
                <img
                    className="inline-block w-full h-auto rounded-lg"
                    src={image}
                    alt={"Sharkpunk #" + props.tokenId}
                />
            )}
            <div className="text-xs md:text-sm text-center mt-2">
                {props.loading ? "LOADING..." : "SHARKPUNK #" + props.tokenId}
            </div>
        </div>
    );
};

export default SharkImage;
