import React from "react";

const DiscordButton = () => {
    return (
        <a
            href="https://discord.gg/sharkpunks"
            target="_blank"
            rel="noreferrer"
            className="inline-block w-full md:w-auto text-sm text-center md:text-md mr-4 px-8 py-4 leading-none border border-discord rounded hover:backdrop-sepia bg-discord text-white mt-4 lg:mt-0"
        >
            DISCORD
        </a>
    );
};

export default DiscordButton;
