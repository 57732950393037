import { useState } from "react";

import abi from "@sharkpunks/contracts/abis/NFTSigmoidCurveOffering.json";
import deployment from "@sharkpunks/contracts/deployments/mainnet/NFTSigmoidCurveOffering.json";
import { NFTSigmoidCurveOffering } from "@sharkpunks/contracts/typechain";
import { useEthers } from "@usedapp/core";
import { BigNumber, Contract } from "ethers";
import { useAsyncEffect } from "use-async-effect";

const INITIAL_PRICE = BigNumber.from(333).mul(BigNumber.from(10).pow(14));
const INFLATION_RATE = 1002496;
const INFLATION_BASE = 1000000;

const useSigmoidCurveOffering = () => {
    const { library, account } = useEthers();
    const [loading, setLoading] = useState(false);
    const [error, setError] = useState("");
    const [tokenId, setTokenId] = useState<number>();
    const [price, setPrice] = useState<BigNumber>(INITIAL_PRICE);
    const [lastUpdated, setLastUpdated] = useState(Date.now());
    const [minting, setMinting] = useState(false);
    const [myTokenId, setMyTokenId] = useState<number>();

    useAsyncEffect(async () => {
        if (library) {
            setError("");
            setLoading(true);
            setTokenId(undefined);
            try {
                const contract = new Contract(deployment.address, abi, library) as NFTSigmoidCurveOffering;
                const events = await contract.queryFilter(contract.filters.Mint());
                if (events.length > 0) {
                    const id = events[events.length - 1].args.tokenId.toNumber() + 1;
                    setTokenId(id);
                    setPrice(await contract.priceOf(id));
                } else {
                    setError("CANNOT LOAD THE LATEST TOKEN ID");
                }
            } catch (e) {
                setError((e as Error).message);
            } finally {
                setLoading(false);
            }
        }
    }, [library, lastUpdated]);

    const onMint = async () => {
        if (library && account && price) {
            setMinting(true);
            setMyTokenId(undefined);
            try {
                const contract = new Contract(deployment.address, abi, library.getSigner()) as NFTSigmoidCurveOffering;
                const tx = await contract.mint(account, { value: price.mul(101).div(100) });
                const { events } = await tx.wait();
                if (events) {
                    const event = events.find(e => e.address === deployment.address && e.event === "Mint");
                    if (event) {
                        setMyTokenId(event.args?.tokenId);
                    }
                }
                setLastUpdated(Date.now());
            } catch (e) {
                setError((e as Error).message);
            } finally {
                setMinting(false);
            }
        }
    };

    const onMintDiscounted = async () => {
        if (library && account && price) {
            setMinting(true);
            setMyTokenId(undefined);
            try {
                const contract = new Contract(deployment.address, abi, library.getSigner()) as NFTSigmoidCurveOffering;
                const tx = await contract.mintDiscounted(account, { value: price.mul(101).div(100) });
                const { events } = await tx.wait();
                if (events) {
                    const event = events.find(e => e.address === deployment.address && e.event === "Mint");
                    if (event) {
                        setMyTokenId(event.args?.tokenId);
                    }
                }
                setLastUpdated(Date.now());
            } catch (e) {
                setError((e as Error).message);
            } finally {
                setMinting(false);
            }
        }
    };

    const onMintBatch = async (length: number) => {
        if (library && account && price) {
            setMinting(true);
            setMyTokenId(undefined);
            try {
                const contract = new Contract(deployment.address, abi, library.getSigner()) as NFTSigmoidCurveOffering;
                const tx = await contract.mintBatch(account, length, { value: calculateTotalPrice(price, length) });
                const { events } = await tx.wait();
                if (events) {
                    const event = events.find(e => e.address === deployment.address && e.event === "Mint");
                    if (event) {
                        setMyTokenId(event.args?.tokenId);
                    }
                }
                setLastUpdated(Date.now());
            } catch (e) {
                setError((e as Error).message);
            } finally {
                setMinting(false);
            }
        }
    };

    const onMintBatchDiscounted = async (length: number) => {
        if (library && account && price) {
            setMinting(true);
            setMyTokenId(undefined);
            try {
                const contract = new Contract(deployment.address, abi, library.getSigner()) as NFTSigmoidCurveOffering;
                const tx = await contract.mintBatch(account, length, { value: calculateTotalPrice(price, length) });
                const { events } = await tx.wait();
                if (events) {
                    const event = events.find(e => e.address === deployment.address && e.event === "Mint");
                    if (event) {
                        setMyTokenId(event.args?.tokenId);
                    }
                }
                setLastUpdated(Date.now());
            } catch (e) {
                setError((e as Error).message);
            } finally {
                setMinting(false);
            }
        }
    };

    return {
        loading,
        error,
        tokenId,
        price,
        myTokenId,
        minting,
        onMint,
        onMintDiscounted,
        onMintBatch,
        onMintBatchDiscounted,
    };
};

const calculateTotalPrice = (startingPrice: BigNumber, length: number) => {
    let value = BigNumber.from("0");
    let price = BigNumber.from(startingPrice);
    for (let i = 0; i < length; i++) {
        value = value.add(price);
        price = price.mul(INFLATION_RATE).div(INFLATION_BASE);
    }
    value = value.mul(101).div(100);
    return value;
};

export default useSigmoidCurveOffering;
