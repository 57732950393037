import React from "react";

import { Auth0Provider } from "@auth0/auth0-react";
import { Mainnet, DAppProvider, Config } from "@usedapp/core";
import { providers } from "ethers";
import { HashRouter, Routes, Route } from "react-router-dom";

import Footer from "./components/Footer";
import Header from "./components/Header";
import { ALCHEMY_API_KEY } from "./constants";
import Claim from "./pages/Claim";
import Collection from "./pages/Collection";
import Home from "./pages/Home";
import Mint from "./pages/Mint";
import NotFound from "./pages/NotFound";

const config: Config = {
    autoConnect: false,
    readOnlyChainId: Mainnet.chainId,
    readOnlyUrls: {
        [Mainnet.chainId]: new providers.AlchemyProvider(1, ALCHEMY_API_KEY),
    },
};

const onRedirect = (): void => {
    window.history.replaceState({}, document.title, window.location.pathname + window.location.hash);
};

function App() {
    return (
        <DAppProvider config={config}>
            <Auth0Provider
                domain="dev-mlyd9xws.us.auth0.com"
                clientId="8r1Cj9YbltdjSSzGAt9Gi8rSrl2WMwa1"
                onRedirectCallback={onRedirect}
            >
                <div>
                    <Header />
                    <HashRouter basename={process.env.PUBLIC_URL}>
                        <Routes>
                            <Route path="/claim/:slug" element={<Claim />} />
                            <Route path="/mint" element={<Mint />} />
                            <Route path="/collection/:account" element={<Collection />} />
                            <Route path="/" element={<Home />} />
                            <Route path="/404" element={<NotFound />} />
                            <Route path="*" element={<NotFound />} />
                        </Routes>
                    </HashRouter>
                    <Footer />
                </div>
            </Auth0Provider>
        </DAppProvider>
    );
}

export default App;
