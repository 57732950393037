import { useEffect, useState } from "react";

import { useAuth0, User } from "@auth0/auth0-react";
import { useAsyncEffect } from "use-async-effect";

import { API_SERVER } from "../constants";
import useNFTAirdrops from "./useNFTAirdrops";
import useSharkpunks from "./useSharkpunks";

export interface ClaimState {
    slug: string;
    user?: User;
    setUser: (user?: User) => void;
    tweetUrl?: string;
    setTweetUrl: (error?: string) => void;
    auth?: Auth;
    setAuth: (auth?: Auth) => void;
    error?: string;
    setError: (error?: string) => void;
    accessToken?: string;
    onAuthenticateWithTwitter: () => Promise<void>;
    onClaim: () => Promise<void>;
    claiming: boolean;
    loadingNextTokenId: boolean;
    nextTokenId?: number;
}

export interface Auth {
    slug: string;
    id: string;
    signature: Signature;
}

export interface Signature {
    v: number;
    r: string;
    s: string;
}

const SLUGS = ["750", "oh-geez", "top69"];

const useClaimState = (slug: string): ClaimState => {
    const { getAccessTokenSilently } = useAuth0();
    const [user, setUser] = useState<User>();
    const [tweetUrl, setTweetUrl] = useState<string>();
    const [auth, setAuth] = useState<Auth>();
    const [error, setError] = useState<string>();
    const [accessToken, setAccessToken] = useState<string>();
    const [claiming, setClaiming] = useState(false);
    const { loadingNextTokenId, nextTokenId } = useSharkpunks({ upperLimit: slug === "top69" ? 231 : 1083 });
    const { claimShark } = useNFTAirdrops(slug === "top69");

    useEffect(() => {
        if (!slug || !SLUGS.includes(slug)) {
            window.location.href = "/#/404";
        }
    }, [slug]);

    useAsyncEffect(async () => {
        if (user) {
            setAccessToken(await getAccessTokenSilently());
        }
    }, [user]);

    useAsyncEffect(async () => {
        if ((slug === "oh-geez" || slug === "top69") && accessToken) {
            setAuth(undefined);
            setError(undefined);
            try {
                const resp = await fetch(API_SERVER + `auth/?slug=${slug}&access_token=${accessToken}`);
                const json = await resp.json();
                console.log(json);
                if (resp.ok) {
                    setAuth(json);
                } else {
                    setError(json.errors[0].message);
                }
            } catch (e) {
                setError((e as Error).message);
            }
        }
    }, [accessToken]);

    const onAuthenticateWithTwitter = async () => {
        setAuth(undefined);
        setError(undefined);
        try {
            const resp = await fetch(
                API_SERVER + `auth/?slug=${slug}&access_token=${accessToken}&tweet_url=${tweetUrl}`
            );
            const json = await resp.json();
            if (resp.ok) {
                setAuth(json);
            } else {
                setError(json.errors[0].message);
            }
        } catch (e) {
            setError((e as Error).message);
        }
    };

    const onClaim = async () => {
        if (auth) {
            setClaiming(true);
            setError(undefined);
            try {
                await claimShark(auth.slug, auth.id, auth.signature.v, auth.signature.r, auth.signature.s);
            } catch (e) {
                setError((e as Error).message);
            } finally {
                setClaiming(false);
            }
        }
    };

    return {
        slug,
        user,
        setUser,
        tweetUrl,
        setTweetUrl,
        auth,
        setAuth,
        error,
        setError,
        accessToken,
        onAuthenticateWithTwitter,
        onClaim,
        claiming,
        loadingNextTokenId,
        nextTokenId,
    };
};

export default useClaimState;
