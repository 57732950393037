import React, { useEffect } from "react";

import { shortenAddress, useLookupAddress } from "@usedapp/core";
import { constants } from "ethers";
import { FaTwitter } from "react-icons/fa";
import { useParams } from "react-router-dom";

import Loading from "../components/Loading";
import SharkImage from "../components/SharkImage";
import useSharkpunks from "../hooks/useSharkpunks";

const Collection = () => {
    const { account } = useParams();
    const ens = useLookupAddress();
    const { myTokenIds, loadingMyTokenIds } = useSharkpunks({ account });

    useEffect(() => {
        if (!account) {
            window.location.href = "/#/404";
        }
    }, [account]);

    const onTweet = () => {
        window.open(
            "https://twitter.com/intent/tweet?text=" +
                encodeURIComponent("Yo, check my cool @SharkpunksNFT collection.\n👇") +
                "&url=" +
                encodeURIComponent(window.location.origin + "/#/collection/" + account),
            "_blank"
        );
    };

    return (
        <div className="container mx-auto">
            <div>
                <div className="text-3xl lg:text-4xl xl:text-5xl sm:leading-snug lg:leading-snug xl:leading-snug mt-40 text-right">
                    {ens ?? shortenAddress(account || constants.AddressZero)}
                </div>
                <div className="text-xl lg:text-2xl xl:text-3xl leading-snug lg:leading-snug xl:leading-snug mt-4 text-right">
                    {account
                        ? loadingMyTokenIds
                            ? "LOADING..."
                            : "A COLLECTION OF " + (myTokenIds?.length || 0) + " SHARKPUNKS"
                        : "CONNECT WALLET"}
                </div>
                {loadingMyTokenIds && (
                    <div className="mt-24">
                        <Loading />
                    </div>
                )}
                {!loadingMyTokenIds && myTokenIds && myTokenIds.length > 0 && (
                    <>
                        <div className="mt-24 grid grid-cols-2 md:grid-cols-4">
                            {myTokenIds.map(tokenId => (
                                <div key={tokenId} className="w-full mb-8">
                                    <a
                                        href={
                                            "https://opensea.io/assets/ethereum/0xa59a5b0c946086d6884455a6a556729d747d16d3/" +
                                            tokenId
                                        }
                                        target="_blank"
                                        rel="noreferrer"
                                    >
                                        <SharkImage tokenId={tokenId} />
                                    </a>
                                </div>
                            ))}
                        </div>
                        <div className="mt-8 w-full md:flex">
                            <button
                                onClick={onTweet}
                                className="w-full text-sm md:text-base px-8 md:px-12 py-4 leading-none border rounded hover:backdrop-sepia text-white border-twitter bg-twitter flex justify-center items-center md:mr-2"
                            >
                                <FaTwitter fontSize={22} className="mt-1 mr-4" />
                                TWEET THIS COLLECTION
                            </button>
                            <div className="text-center my-4 block md:hidden">or</div>
                            <a
                                href="/#/mint"
                                className="block w-full bg-text text-white text-sm text-center md:text-base px-8 md:px-12 py-4 leading-none border rounded hover:backdrop-sepia mt-4 md:mt-0 md:ml-2"
                            >
                                MINT SHARKPUNKS
                            </a>
                        </div>
                    </>
                )}
                {!loadingMyTokenIds && (!myTokenIds || myTokenIds.length == 0) && (
                    <div className="flex flex-col mt-48">
                        <a
                            href="/#/mint"
                            className="mx-auto bg-text text-white text-sm text-center md:text-lg px-8 md:px-12 py-4 leading-none border rounded hover:backdrop-sepia"
                        >
                            MINT NOW
                        </a>
                        <div className="text-base md:text-lg mt-4 text-center">
                            THIS COLLECTION DOESN&apos;T HAVE ANY SHARKPUNK.
                        </div>
                    </div>
                )}
            </div>
        </div>
    );
};

export default Collection;
