import React from "react";

import DiscordButton from "./DiscordButton";
import TwitterButton from "./TwitterButton";

const Section5 = () => {
    return (
        <div>
            <div className="container mx-auto mt-72 md:flex">
                <div className="w-full md:w-2/3">
                    <div className="text-3xl lg:text-4xl xl:text-5xl sm:leading-snug lg:leading-snug xl:leading-snug">
                        STAY IN THE LOOP
                    </div>
                    <div className="text-sm leading-loose mt-4 mb-8">
                        FOLLOW TWITTER & JOIN DISCORD TO GET THE LATEST UPDATES!
                    </div>
                    <div className="md:flex flex-row mt-8">
                        <TwitterButton />
                        <DiscordButton />
                    </div>
                </div>
                <div className="w-full p-12 md:w-1/3 md:p-0 md:pt-4 lg:pl-0 xl:pt-8">
                    <img className="w-full object-fill" src="/img/shark-plain.png" alt="Shark" />
                </div>
            </div>
        </div>
    );
};

export default Section5;
