import React from "react";

const Section2 = () => {
    return (
        <div>
            <div className="container mx-auto mt-72">
                <div className="lg:flex justify-between">
                    <div className="w-full lg:w-1/3">
                        <div className="text-2xl lg:text-3xl xl:text-4xl sm:leading-snug lg:leading-snug xl:leading-snug">
                            HOW CAN YOU MAKE YOUR SHARKPUNKS EARN YIELD?
                        </div>
                    </div>
                    <div className="w-full mt-4 lg:w-2/3 lg:mt-0 lg:ml-16">
                        <div className="text-md leading-loose">
                            SHARKPUNKS IS THE FIRST PARTNER PROJECT OF LEVX.IO, A VE TOKENOMICS BASED YIELD FARMING
                            PLATFORM TO PROVIDE &quot;DEFLATIONARY&quot; REWARDS FOR FARMERS.
                        </div>
                        <div className="text-md leading-loose mt-4">
                            BY STAKING YOUR SHARKPUNKS + VE LEVX, YOU GET DISTRIBUTED REWARD TOKENS IN PROPORTIONAL TO
                            THE BOOST IT GOT VOTED.
                        </div>
                        <div className="text-md leading-loose mt-4">FOR MORE DETAILS, CHECK THE ARTICLE BELOW:</div>
                        <div>
                            <a
                                href="https://blog.levxdao.org/how-can-you-make-your-nfts-earn-yield-49f31772c21"
                                target="_blank"
                                rel="noreferrer"
                                className="inline-block text-sm px-4 py-4 leading-none border rounded hover:backdrop-sepia mt-4"
                            >
                                READ THE FULL ARTICLE
                            </a>
                        </div>
                    </div>
                </div>
                <div className="flex mt-16">
                    <div className="w-1/4">
                        <img src="img/section2/shark0.png" />
                    </div>
                    <div className="w-1/4">
                        <img src="img/section2/shark1.png" />
                    </div>
                    <div className="w-1/4">
                        <img src="img/section2/shark2.png" />
                    </div>
                    <div className="w-1/4">
                        <img src="img/section2/shark3.png" />
                    </div>
                </div>
            </div>
        </div>
    );
};

export default Section2;
